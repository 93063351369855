.footer {
  background-color: #175b62;
}

.footer .copyright {
  padding-top: 50px;
  padding-bottom: 20px;

  font-weight: 300;
  font-size: 10px;
  /* line-height: 32px; */
  color: #ffffff;
  opacity: 0.5;
  text-align: center;
}

.tagline {
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.5;
}

.footer-left {
  padding-top: 40px;
  /* width: 50%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.footer-right {
  padding-top: 40px;
  /* width: 50%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 60px;
}

.social-link img {
  width: 100%;
  height: 100%;
  padding: 25%;
  filter: invert(100%) sepia(1%) saturate(4%) hue-rotate(3deg) brightness(103%)
    contrast(101%);
  /* filter: invert(27%) sepia(43%) saturate(675%) hue-rotate(137deg)
    brightness(99%) contrast(95%); */
}

.social-link-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.social-link {
  width: 80px;
  height: 80px;
  box-shadow: 0px 0px 0px 1px #f4f4f4 inset;
  background-color: #175b62;
  /* background-color: #f4f4f4; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px 40px 0px 0px;
  transition: 0.3s ease-in-out;
}

.social-link.btn {
  padding: unset;
}

.social-link:hover {
  background-color: #f4f4f4;
  /* box-shadow: 0px 0px 0px 1px #fff inset;
  background-color: #175b62; */
}

.social-link img:hover {
  filter: invert(27%) sepia(43%) saturate(675%) hue-rotate(137deg)
    brightness(99%) contrast(95%);
  /* filter: invert(100%) sepia(1%) saturate(4%) hue-rotate(3deg) brightness(103%)
    contrast(101%); */
}

.footer-right .contact p {
  text-align: right;
  font-size: 12px !important;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 8px;
}

button {
  outline: none;
}

.footer a {
  color: unset;
  text-decoration: unset;
}

.copyright b {
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .footer-left,
  .footer-right {
    align-items: center;
    gap: 20px;
  }

  .footer-right .contact p,
  .tagline {
    text-align: center;
  }
}
