.service-left {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.service-img {
  width: 280px;
  height: 450px;
  border-radius: 200px;
  object-fit: cover;
}

.service-right {
  /* width: 100%; */
  max-width: 600px;
}

.pre-heading {
  font-size: 14px;
}

.service-right h1 {
  font-weight: bolder;
  margin-bottom: 32px;
}

.service-content {
  margin-bottom: 32px;
}

.dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  background-color: #175b62;
  /* margin-right: 10px; */
}

.dot-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.dot-text {
  font-size: 16px;
  /* font-weight: bold; */
}

/* .viewmore {
  color: #175b62;
}

.viewmore :hover {
  color: #175b62;
} */

.service-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  align-content: start;
  gap: 80px;
  padding: 40px 0;
  /* margin-bottom: 80px; */
}

@media only screen and (max-width: 600px) {
  .service-container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .pre-heading,
  .service-right h1 {
    text-align: center;
  }

  .service-content {
    text-align: left;
  }
}
