/* .about-flex {
  display: flex;
  flex-direction: column;
  gap: 32px;
} */

.doctor-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 120px;
}

.doctor-group img {
  width: 360px;
  height: 600px;
  border-radius: 1200px;
  object-fit: cover;
}

.doctor-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .doctor-right {
    width: unset;
  }

  .doctor-right h1,
  p {
    text-align: center;
  }

  .doctor-right h1 {
    font-size: 42px;
  }
}
