/* @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Baskerville Normal';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville Normal'),
    url('./assets/fonts/baskerville/baskvl.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville Regular'),
    url('./assets/fonts/baskerville/baskervi.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville Light-Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville Light-Italic'),
    url('./assets/fonts/baskerville/BASKE10.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville Light';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville Light'),
    url('./assets/fonts/baskerville/BASKE9.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville LightA';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville LightA'),
    url('./assets/fonts/baskerville/BASKRV_L.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville Bold'),
    url('./assets/fonts/baskerville/BASKE1.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville Bold'),
    url('./assets/fonts/baskerville/Baskerville Bold font.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville BT Roman';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville BT Roman'),
    url('./assets/fonts/baskerville/BaskervilleBT.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville BT Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville BT Italic'),
    url('./assets/fonts/baskerville/BaskervilleItalicBT.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville BT Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville BT Bold'),
    url('./assets/fonts/baskerville/BaskervilleBoldBT.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville BT Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville BT Bold'),
    url('./assets/fonts/baskerville/baskerville bold bt.woff') format('woff');
}

@font-face {
  font-family: 'Baskerville BT Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Baskerville BT Bold Italic'),
    url('./assets/fonts/baskerville/BaskervilleBoldItalicBT.woff')
      format('woff');
}

.primary-font {
  font-family: 'Baskerville', serif;
}

.secondary-font {
  font-family: 'Inter', sans-serif;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #fbf7f2;
  margin: 0 !important;
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
.heading {
  font-family: 'Baskerville', serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: unset;
}

h1 {
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
}

h4 {
  font-size: 42px;
  line-height: 60px;
  font-weight: normal;
}

p {
  font-size: 18px;
}

.heading {
  margin-bottom: 24px;
}

.heading-subtext {
  margin: auto;
  max-width: 620px;
}

.container {
  width: 90%;
  /* max-width: 1120px; */
  margin: auto;
}

.header-container {
  width: 100%;
}

.btn {
  border: none;
  border-radius: 50px;
  padding: 10px 30px;
}

.btn-lg {
  width: 260px;
  height: 56px;
  border-radius: 64px;
}

.btn-green {
  background: #175b62;
  color: white;
}

.btn-green:hover {
  background: #11464c;
  color: white;
}

.accent-bg {
  background-color: #f2e4d5;
}
