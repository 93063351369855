.header {
  background-color: #175b62 !important;
  padding: 8px 0;
  /* height: 140px; */
  /* padding-left: 124px; */
}

.dropdown-item {
  color: #212529;
}

.custom-dropdown-item:hover {
  background-color: white;
  color: white;
}

.dropdown-toggle:focus {
  outline: none;
}

.logo {
  /* width: 120px; */
  height: 120px;
  margin-right: 20px;
}

.logo-main {
  font-family: 'Baskerville', serif;
  font-size: 42px;
  line-height: 50px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-image: linear-gradient(#f5eb85 0%, #a88732 100%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-subtext {
  font-family: 'Baskerville', serif;
  margin-top: 4px;
  font-size: 16px;
  letter-spacing: 4px;
  line-height: 20px;
  background-image: linear-gradient(#f5eb85 0%, #a88732 100%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: end;
  align-items: center;
}

.nav-item {
  /* margin: 0 10px; */
  font-size: 18px;
  font-family: 'Inter', sans-serif;
}

.nav-link {
  color: #ffffff;
  opacity: 0.5;

  font-weight: 400;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.2s ease-in-out;
  padding: unset;
}

.active {
  opacity: unset;
}

.nav-link:hover {
  color: #fff;
  opacity: unset;
}

.header-logo-group {
  display: flex;
  justify-content: start;
  align-items: center;
}

.header-logo-group:hover {
  cursor: pointer;
}

.logo-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

#collapsible-nav-dropdown {
  border: none;
  /* color: inherit; */
  font-size: inherit;
  font-family: 'Inter', sans-serif;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 80px;
  }

  .logo-main {
    font-size: 34px;
    line-height: 40px;
  }

  .logo-subtext {
    font-size: 12px;
    letter-spacing: 4px;
    line-height: 20px;
  }

  .nav {
    flex-direction: column;
    gap: 8px;
    align-items: end;
  }

  .nav-item {
    text-align: right;
  }
}
