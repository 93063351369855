section {
  padding-top: 100px;
  padding-bottom: 100px;
}

section.main {
  padding: unset;
  padding-top: 160px;
  padding-bottom: 160px;
  position: relative;
  z-index: 0;
}

.main-left {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.main-right img {
  width: 216px;
}

.about-img-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* height: 100%; */
}

.about-img-group img {
  width: 216px;
}

.main-right {
  position: absolute;
  right: -108px;
  top: 15%;
}

.semi-img {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.service-card-group {
  margin-top: 120px;
  margin-bottom: 80px;
  /* width: 100%; */
}

.service-card-subgroup {
  margin-top: 72px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 28px;
}

.service-card {
  width: 240px;
  margin-bottom: 20px;
}

.service-card-main {
  width: 100%;
  height: 220px;
  background-color: #e8e3df;
  border-radius: 18px;
  margin-bottom: 35px;
}

.service-card-main:hover {
  background-color: #e4ddd7;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.service-card-main p {
  font-family: 'Baskerville';
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  padding-top: 136px;
}

.service-card-top {
  width: 115px;
  height: 115.39px;

  background: #c2b8b3;
  border-radius: 148.895px 148.895px 0px 0px;

  position: absolute;
  margin-top: -35px;
  margin-left: 62.5px;

  display: flex;
  align-items: center;
}

.service-icon {
  width: 64px;
  margin: auto;
}

.appointment {
  background-color: rgba(189, 181, 179, 0.3);
}

.text-sm {
  font-size: 14px;
}

.text-translucent {
  opacity: 0.5;
}

.locate-icon {
  filter: invert(95%) sepia(0%) saturate(18%) hue-rotate(321deg)
    brightness(105%) contrast(104%);
  width: 20px;
}

.appointment-left,
.appointment-right {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: start;
  align-items: center;
}

.cta-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.cta-group {
  display: flex;
  gap: 20px;
}

/* .main-cta {
  width: 50%;
}

.main-cta .btn {
  height: 50px;
} */
.main-cta {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .main-right {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .main-right {
    display: none;
  }

  .main-left {
    text-align: center;
  }

  .main-cta {
    margin: auto;
  }

  .about-img-group img {
    width: 80px;
  }

  .cta-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .btn {
    padding: 8px 20px;
  }
}
